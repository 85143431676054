@import 'custom.scss';

.Search {
    width: 100%;

    .SearchInput {
        display: flex;
    }

    .form-control {
        //font-size: 13px;
        font-size: 14px;
    }

    .btn {
        //font-size: 13px;
        font-size: 14px;
    }

    .btn:hover,
    .btn:focus {
        background: #fff;
        color: #000;
    }

    input {
        font-size: 14px !important;
        color: #484848;
    }
}