.pagination {
    margin: 10px auto;
    justify-content: center;
    //font-size: 13px;
    font-size: 14px;
}

.visually-hidden {
    display: none;
}

.Pagination {
    display: flex;
    align-items: center;

    p {
        margin: 0 5px 0 20px !important;
    }

    .Results {
        padding: 2px 5px;
    }
}