@import 'custom.scss';
@import './Page.scss';

.DetailPage {

    .Media {}

    .PageHeader {
        svg {
            cursor: pointer;
            font-size: 20px;
        }
    }

    .Label {
        font-weight: bold;
        margin-bottom: 0;
        background: #f5f5f5;
        padding: 5px;
        border-bottom: 1px solid #eeeeee;
        display: flex;

        >span {
            width: 100%;
        }

        svg {
            cursor: pointer;
        }
    }

    .Text {
        padding: 5px;
        word-break: break-word;

        a {
            display: block;
        }
    }

    .Control {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            display: inline-block;
            width: 20px;
            height: 20px;
            transform: scale(1.5);
            cursor: pointer;
        }
    }

    .Content {
        padding: 20px;
        margin-top: 20px;
    }

    .Results {
        // font-size: 13px;
        font-size: 14px;
        margin: 10px 0 0;
        text-align: center;
    }

    .ResultsPage {
        //font-size: 13px;
        font-size: 14px;
        text-align: center;
    }

    .Prev {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3E%3Cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3E%3C/svg%3E");
        filter: invert(1) grayscale(100);
    }

    .Next {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3E%3Cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
        filter: invert(1) grayscale(100);
    }

    .AddToPlaylist {
        cursor: pointer;
        font-size: 20px;
    }

    .InList {
        color: #999900;
    }

    .CopyToClipBoard,
    .AddToPlaylistIcon {
        &:focus {
            color: #d5001c;
        }
    }
}

.detailtab {
    // border: 1px solid red;

    &:focus {
        border: 1px solid #d5001c;
    }
}