.Video {
    max-width: 100%;
    min-width: 100%;
}

.Image {
    max-width: 100%;
    min-width: 100%;
}

.Audio {
    max-width: 100%;
    min-width: 100%;
    outline: none;
}

.Icon {
    display: flex;
    flex-direction: column;
    color: #dfdfdf;
    height: 100%;
    justify-content: center;
    align-items: center;
	
	svg {
		margin-bottom: 20px;
	}
    
    p {
        margin: 10px;
    }
}

.Youtube {
    width: 100%;
    height: 700px;
    
    iframe {
        width: 100%;
        height: 100%;
    }
}

.ImageContainer {
    position: relative;
    display: flex;
    align-items: center;
	justify-content: center;
	
	svg {
		color: #dfdfdf;
	}
    
    .Overlay {
        position: absolute;
        right: 0;
        left: 0;
        background: #000000bb;
        color: #fff;
        padding: 1rem 2.5rem 0;
        font-size: 16px;
        
        a {
            color: #fff !important;
            text-decoration: underline;
        }
    }
}
