@import 'custom.scss';
@import 'Pages/Page.scss';

.Sidebar {
    //font-size: 13px;
    font-size: 14px;

    h3 {
        margin: .1em 0;
        line-height: 1.4em;
        padding: 5px;
    }

    .Button {
        text-align: center;

        button {
            //font-size: 13px;
            font-size: 14px !important;
        }
    }

    .SidebarControl {
        cursor: pointer;
        border-bottom: 1px solid #999;
        margin-bottom: 20px;
        display: flex;
        align-items: center;

        h3 {
            flex: 1;
        }

        svg {
            margin: 5px;
        }
    }

    .Filters {
        &.show-true {
            display: block;
        }

        &.show-false {
            display: none;
        }
    }

    .PageHeader {
        @media (max-width: 1200px) {
            .SidebarSearch {
                padding: 0 5px;
            }

        }
    }


}

@media (min-width: 1200px) {
    .Mobile {
        display: none !important;
    }
}