.ClipGrid.card {
    width: 32%;
    margin: 0.5%;
    border-width: 1px;

    border-radius: 0 !important;
    cursor: pointer;
    padding: 0;

    overflow: hidden;

    transition: all 0.25s ease-out;
    
    aspect-ratio: 16 / 9 !important;
    
    font-size: 0.85em;

    /*&:hover {
        transform: scale(1.03);
        z-index: 100;
    }*/

    &:focus {
        border: 1px solid #d5001c;
    }
    
    .ImageGridContainer {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;
        right: 0;
        justify-content: center;
        display: flex;
        background: #000;
        
        img {
            height: 100%;
        }
    }


    .CardOverlay {
        padding: 50px 10px 5px;
        background: rgb(0, 0, 0);
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.85) 100%);

        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        
        h2 {
            font-size: 1.1em;
        }
    }

    .CardControls {
        padding: 0px 10px;

        text-align: right;
        color: #fff;

        background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);

        font-size: 18px;

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }

    .hover {
        max-height: 0;

        p {
            line-height: 1.4em;
        }

        @supports (-webkit-line-clamp: 3) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }

        transition: max-height 0.25s ease-out;
    }

    &:hover .hover {
        max-height: 75px;
    }

    &:focus .hover {
        max-height: 75px;
    }

    h3 {
        color: #fff;
    }

    p {
        line-height: 1.8em;
        margin: 0;
        color: #fff;
    }
}

.ClipList {
    width: 100%;
    border-width: 1px;

    border-radius: 0 !important;
    cursor: pointer;
    padding: 10px;
    
    font-size: 0.85em;

    overflow: hidden;

    transition: all 0.25s ease-out;

    &:nth-child(2n) {
        background: #f5f5f5;
    }

    /*&:hover {
        transform: scale(1.03);
        z-index: 100;
    }*/

    img {
        max-width: 100%;
        object-fit: cover;
    }

    p:last-child {
        @supports (-webkit-line-clamp: 2) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }

    .CardControls {
        padding: 5px 10px;

        text-align: right;


        font-size: 18px;

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }

    .CardOverlay {
        padding: 50px 10px 5px;
        background: rgb(0, 0, 0);
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.85) 100%);

        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .hover {
        max-height: 0;

        @supports (-webkit-line-clamp: 3) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }

        transition: max-height 0.25s ease-out;
    }

    &:hover .hover {
        max-height: 75px;
    }

    h3 {}

    p {
        line-height: 1.8em;
        margin: 0;
    }
}


.small {
    width: 100%;
}


.ellipsis {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@media (max-width: 1200px) {
    .ClipGrid.card {
        width: 49%;
        margin: 0.5%;
    }
}

@media (max-width: 992px) {
    .ClipGrid {
        width: 49%;
        margin: 0.5%;
    }
}

@media (max-width: 576px) {
    .ClipGrid.card {
        width: 99%;
        margin: 0.5%;
    }
}

/*
@media (max-width: 978px) {
    .col-md-3 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .col-md-9 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
*/
