.PageHeader {
    background: #fff;
    /*#f5f5f5;*/
    min-height: 80px;
    align-items: center;
    margin-bottom: 20px;

    h1 {
        font-weight: 400;
        margin: 0;
        line-height: 1.7;
    }

    p {
        margin: 0;
        line-height: 2;
    }

    button {
        //font-size: 13px;
        font-size: 14px;
    }

    >div {
        //padding-right: calc(var(--bs-gutter-x) * .2);
        //padding-left: calc(var(--bs-gutter-x) * .2);
    }

    .ViewMode {
        button {
            padding: 6px 10px;
            outline: none;
        }

        .Grid {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        .List {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
}

@media (max-width: 992px) {
    .PageHeader {
        min-height: 60px;
    }
}