@import 'custom.scss';

.PlaylistPage {

    .pagination {
        margin: 10px auto;
        justify-content: center;
        //font-size: 13px;
        font-size: 14px;
    }

    .visually-hidden {
        display: none;
    }

    .removeplaylist-icon {
        &:focus {
            color: red;
        }
    }

}