@import 'custom.scss';
@import 'Pages/Page.scss';

.Sidebar {
    //font-size: 13px;
    font-size: 14px;

    .caret-down {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 2px;
        vertical-align: middle;
        border-top: 4px dashed;
        border-top: 4px solid;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
    }

    .caret-up {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 2px;
        vertical-align: middle;
        border-bottom: 4px dashed;
        border-bottom: 4px solid;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
    }

    .Search {
        .search-input {
            display: flex;
        }

        .col,
        .col-md-auto {
            /*margin: 0;
            padding: 0;*/
        }

        .form-control {
            //font-size: 13px;
            font-size: 14px;
        }

        .btn {
            /*margin-left: 5px;*/
            //font-size: 13px;
            font-size: 14px;
        }
    }

    h3 {

        margin: .1em 0;
        line-height: 1.4em;
        padding: 5px;
    }

    .Button {

        text-align: center;

        button {
            /*width: 100%;*/
            font-size: 13px;
        }
    }

    .SidebarControl {
        cursor: pointer;
        border-bottom: 1px solid #999;
        margin-bottom: 0px;
        display: flex;
        align-items: center;

        h3 {
            flex: 1;
        }

        svg {
            margin: 5px;
        }
    }

    .Filters {
        &.show-true {
            display: block;
        }

        &.show-false {
            display: none;
        }
    }

    .Controls {
        button {
            font-size: 13px;
            margin: 0;
            padding: 3px 6px;
            border: none;
        }
    }

    .PlaylistEntry {
        cursor: pointer;
        padding: 5px;

        &:hover {
            background: #f5f5f5;
        }
    }

    .PlaylistEntryBox {
        padding: 7px 0;
        border-bottom: 1px solid #ccc;
    }

    .spinner-border {
        width: 1rem;
        height: 1rem;
        border-width: 1px;
    }

    .active {
        background: #f5f5f5;
    }
}

.btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: .25em;
    color: #000;
    background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3E%3C/svg%3E") 50%/1em auto no-repeat;
    border: 0;
    border-radius: .25rem;
    opacity: .5;
}

@media (min-width: 1200px) {
    .Mobile {
        display: none !important;
    }
}