@import 'custom.scss';
@import './Page.scss';

.LoginPage {
    
    a {
        color: #484848;
    }
    
    .LoginImage {
        img {
            height: 100%; 
            width: 100%; 
            object-fit: cover;
        }
    }
    
    .LoginForm {
        padding: 25px;
    }
    
    .LoginPassword {
        display: flex;
        align-items: center;
        margin-bottom: 25px;
        position: relative;
        
        svg {
            position: absolute;
            right: 10px;
            cursor: pointer;
        }
    }
    
    .LoginNumber {
        margin-bottom: 25px;
    }
    
    .LoginForgot {
        text-align: right;
    }
    
    .LoginRegister {
        margin-top: 25px;
    }
	
	.LoginError {
		color: #aa0000;
	}
}
