@import 'custom.scss';

.HomePage {



    .carousel-control-prev,
    .carousel-control-next {
        width: 2%;
        color: $grey;
    }

    .carousel-control-prev-icon {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3E%3Cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3E%3C/svg%3E");
        filter: invert(1) grayscale(100);
    }

    .carousel-control-next-icon {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3E%3Cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
        filter: invert(1) grayscale(100);
    }

    .carousel-inner {
        width: 90%;
        margin: 10px auto;
    }

    .carousel-item .row {
        margin-left: 0;
    }

    h1 {
        margin: .1em 0;
        font-size: 1.6em;
        line-height: 1.4em;
        font-weight: 400;
        border-bottom: 1px solid $grey;
    }

}