@import 'custom.scss';
@import './Page.scss';

.AccountPage {
    
    .Label {
        font-weight: bold;
        margin-bottom: 0;
        background: #f5f5f5;
        padding: 5px;
        border-bottom: 1px solid #eeeeee;
    }
    
    .Text {
        padding: 5px;
        word-break: break-word;
    }
    
    .AccountContent {
        max-width: 992px;
    }
    
    a {
        color: #484848;
    }
    
    a:hover {
        color: #000;
    }
    
    .AccountAddress p {
        margin: 0;
    }
}
