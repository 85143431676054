@import 'custom.scss';

.ContentHeader {
    //background: $primary;
    color: #fff;
    margin-bottom: 3px;
    padding: 15px 0;
    //background: linear-gradient(90deg, rgba(207,17,81,1) 0%, rgba(120,100,148,0.7203256302521008) 39%, rgba(39,177,209,1) 89%, rgba(94,180,126,0.9836309523809523) 100%);
    background: linear-gradient(90deg, rgba(63, 146, 152, 1) 58%, rgba(255, 203, 74, 1) 90%);
    position: relative;


    #Wave {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        background-size: 100% 100%;
        background-position: center;
    }

    .Title {
        z-index: 1;
    }

    .TitleLink {
        color: #ffffff;
        text-decoration: none;
    }

    .TitleLink:hover,
    .TitleLink:focus {
        text-decoration: underline;
    }

    h1 {
        margin: .1em 0;
        font-size: 1.6em;
        line-height: 1.4em;
        font-weight: 400;
    }

    p {
        margin: 0 0 5px;
        line-height: 1.1em;
    }

    .nav {
        justify-content: center;
        align-items: center;
        z-index: 1;
    }

    .nav a {
        color: #fff;
    }

    .nav a:hover {
        text-decoration: none;
    }

    .nav-pills .nav-link {
        border: 1px solid #000;
        margin-left: 10px;
        background: #000;
    }

    .nav-pills .nav-link:hover,
    .nav-pills .nav-link:focus {
        background: #fff;
        color: #000;
    }

}