@import 'custom.scss';
@import 'Pages/Page.scss';

.HorizontalBar {
    //font-size: 13px;
    font-size: 14px;

    .Controls {
        background-color: $primary;
        align-items: center;
        margin-bottom: 10px;
    }


    p {
        margin: .1em 0;
        line-height: 1.5em;
        padding: 6px;
    }

    .Button {
        text-align: center;

        button {
            //font-size: 13px;
            font-size: 14px;
        }
    }

    .SidebarControl {
        cursor: pointer;
        color: #fff;
        /*border-bottom: 1px solid #999;*/
        /*margin-bottom: 20px;*/
        display: flex;
        align-items: center;

        h3 {
            flex: 1;
        }

        svg {
            margin: 5px;
        }
    }

    .Filters {
        &.show-true {
            display: block;
        }

        &.show-false {
            display: none;
        }
    }

    .ViewMode {
        padding: 0 1px;
        display: inline-block;

        button {
            padding: 3px 10px;
            outline: none;
            /*background: #fff;*/
        }

        .Grid {
            border-radius: 0;
        }

        .List {
            border-radius: 0;
        }
    }
}

@media (min-width: 1200px) {
    .Mobile {
        display: none !important;
    }
}

//RESPONSIVE BEGIN
@media (max-width: 992px) {
    .HorizontalOptions {
        display: flex;
        justify-content: space-between;
    }
}

@media (max-width: 576px) {
    .HorizontalOptions {
        padding-right: 8px !important;

        >div {
            padding: 0;
            margin: 0 !important;
        }
    }
}

//RESPONSIVE END