/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d8d8d8;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  background: cornflowerblue;
}


/*
#llgc_branded_frame_logo {
    height: 48px;
    width: auto;
}
*/

#llgc_main_content {
  padding-top: 0 !important;
  margin-bottom: 1rem !important;
}

//ACCESSIBILITY BEGIN
#llgc_home_page_mobile a {
  background: transparent;
  //color: white;
}

.llgc_navbar_link {
  &:focus {
    border: 1px solid red;
  }
}

@media (max-width: 992px) {
  .nav-wrapper {
    display: none;
  }
}

//ACCESSIBILITY END


//RESPONSIVE BEGIN
@media (max-width: 1200px) {
  .container {
    margin: 0 auto;
  }

  .llgc_navbar {
    padding-right: 0;
  }

  .navbar-nav {
    height: unset;
    margin-bottom: 10px !important;
  }

  .nav-item.llgc_site_specific_link,
  .nav-item.llgc_hide_nav.injected {
    margin-top: 1px;
    background: #ffcb4a !important;

    a {
      color: #3B3B3B !important;
    }
  }

  @media(max-width: 768px) {
    .page-item.active .page-link {
      z-index: 1 !important;
    }
  }

  @media (max-width: 576px) {
    .llgc_navbar {
      padding-right: 12px;
    }

    .accordion {
      width: 100% !important;
    }
  }
}

//RESPONSIVE END