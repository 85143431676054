@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&family=Roboto:ital,wght@0,400;0,500;1,400;1,500&family=Special+Elite&display=swap');


$body-color: #212529;

$black: #000 !default;
$grey: #484848 !default;

$blue: #1D4EBF !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #198754 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;

$primary: $black !default;
$dark: $black !default;
/*
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-900 !default;
*/

/*
$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
) !default;
*/


$card-border-width: 0;
$card-inner-border-radius: 0;

$card-spacer-y: 0.5rem;
$card-spacer-x: 0.5rem;

$card-color: $body-color;

$white: #fff;
$black: #000;

/*
$navbar-dark-color:                 rgba($white, .75) !default;
$navbar-dark-hover-color:           rgba($white, .90) !default;

$navbar-light-color:                rgba($black, .75) !default;
$navbar-light-hover-color:          rgba($black, .90) !default;

.bg-black {
  background-color: $black;
}
*/


// $card-spacer-y:                     $spacer !default;
// $card-spacer-x:                     $spacer !default;
// $card-title-spacer-y:               $spacer / 2 !default;
// $card-border-width:                 $border-width !default;
// $card-border-radius:                $border-radius !default;
// $card-border-color:                 rgba($black, .125) !default;
// $card-inner-border-radius:          subtract($card-border-radius, $card-border-width) !default;
// $card-cap-padding-y:                $card-spacer-y / 2 !default;
// $card-cap-padding-x:                $card-spacer-x !default;
// $card-cap-bg:                       rgba($black, .03) !default;
// $card-cap-color:                    null !default;
// $card-height:                       null !default;
// $card-color:                        null !default;
// $card-bg:                           $white !default;
// $card-img-overlay-padding:          $spacer !default;
// $card-group-margin:                 $grid-gutter-width / 2 !default;

@import "../node_modules/bootstrap/scss/bootstrap";


:focus {
    box-shadow: none !important;
    outline: none !important;
}

body {
    font-family: 'Roboto', sans-serif !important;
    color: #484848;
    font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Lato', sans-serif !important;
    font-weight: 600;
}

h1 {
    font-size: 21px;
}

h2 {
    font-size: 16px;
}

h3 {
    font-size: 15px;
}

h4 {
    font-size: 14px;
}

h5 {
    //font-size: 13px;
    font-size: 14px;
}

h6 {
    font-size: 12px;
}

/*
.container {
    min-width: 85em;
    width: 75%;
    max-width: unset;
}
*/
/*
@media only screen and (max-width: 1199px) {
    .container {
        min-width: unset;
        width: 100%;
    }
}
*/

.container-main {
    background: #fff;
    /*box-shadow: 0 0 5px #999;
    margin-bottom: 20px;*/
}

.content {
    min-height: 35em;
}

.page-header {
    padding: 10px 0;
}

.full-height {
    height: 100%;
    min-height: 100%;
    max-height: 100%;
}

.hidden {
    display: none !important;
}

.Row {
    display: flex;
}

.ContentWarning {
    h2 {
        font-size: 16px;
    }
    
    h2 * {
        font-size: 16px;
    }
    
    .loading {
        text-align: center;
    }
}

.ContentWarningCheck {
    flex: 1;
    width: 100%;
    align-items: center;
    
    .form-check-label {
        display: flex;
        flex: 1;
        width: 100%;
    }
    
    input {
        margin-right: 5px;
    }
}

.ContentWarningControls {
    flex: 1;
    flex-direction: column;
    width: 100%;
    
    .warn {
        color: red;
    }
}
    
.Link-f:focus {
    color: blue ;   
}

.Check-f {
    display: inline-block;
}

.Check-f .form-check-input:focus {
    border-color: blue !important;   
}

.Btn-f:focus {
    color: black;
    background-color: white;
}

/*
.no-mobile {
    display: none !important;
}

.mobile {
    display: block !important;
}
*/
@media (max-width: 988px) {
    .hide_elm {
        display: none !important;
    }
}

@media (max-width: 992px) {
    .no-tablet {
        display: none !important;
    }

    .tablet {
        display: block !important;
    }
}

@media (max-width: 576px) {
    .no-mobile {
        display: none !important;
    }

    .mobile {
        display: block !important;
    }
}


/*
@media (max-width: 978px) {
  .container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    padding:0;
    margin:0;
  }

  body {
    padding:0;
  }

  .navbar-fixed-top, .navbar-fixed-bottom, .navbar-static-top {
    margin-left: 0;
    margin-right: 0;
    margin-bottom:0;
  }
}
*/
/*
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*/
