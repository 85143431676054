@import 'custom.scss';
@import './Page.scss';

.SearchPage {
	display: flex;
	flex-direction: column;

	.SearchTerm {
		font-family: 'Lato', sans-serif !important;
		font-weight: 600;
		font-size: 15px;
	}

	.DisplayMenu {
		width: 100vw;
		max-width: 400px;
	}

	.dropdown-toggle,
	.dropdown-item {
		//font-size: 13px;
		font-size: 14px;
	}

	.clip-list {
		display: flex;
		flex: 1;
	}

	.clip-list-loading {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.InList {
		color: #999900;
	}

	.SearchStarIcon {
		&:focus {
			border: 1px solid #d5001c;
		}
	}

	.Grid,
	.List {
		&:focus {
			background: grey;
		}
	}

	//RESPONSIVE BEGIN
	.page-item.active .page-link {
		background: black;
	}

	.PageHeader {

		.DisplayMenu {
			padding: 20px;
		}

		@media(min-width: 1201px) {
			z-index: 3;

			.SearchHeaderInput {
				transform: translate(28px, -6px);
				min-width: 310px;
				padding: 0;
			}

			.SearchHeaderLeft {
				transform: translateY(20px);
			}

			.Pagination {
				margin: 0;
				transform: translateY(10px);
				font-size: 14px;
				line-height: 21px;
				padding-right: 0;
			}

			.SearchDisplayOptions {
				position: relative;
				padding-right: 0;
				transform: translateX(10px);
				z-index: 2;
			}
		}

		@media(min-width: 1400px) {
			.SearchHeaderInput {
				min-width: 420px;
			}
		}


		@media (max-width: 1200px) {
			.col {
				min-width: 100%;
				margin-bottom: 5px;
			}

			.SearchHeaderLeft {
				display: flex;
				justify-content: space-between;
				flex-direction: row-reverse;
				align-items: center;
				height: 36px;
				padding: 0;
				padding-left: 10px;

				.ViewMode {
					padding: 0;
				}
			}
		}

		@media(max-width: 992px) {
			h1 {
				color: black;
				text-align: center;
			}

			.pagination {
				margin: 0;
			}

			.SearchDisplayOptions {
				transform: translate(-50px, -165px);
				position: relative;
				z-index: 2;

				.DisplayMenu {
					transform: translate3d(4.5px, 29px, 0px) !important;
					z-index: 5 !important;
				}
			}

			.SearchHeaderInput {
				padding: 0 9px;

			}
		}

		@media (max-width: 550px) {
			.SearchDisplayOptions {
				transform: translate(-60px, -165px);
			}
		}
	}

	//RESPONSIVE END
}