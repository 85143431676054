@import 'custom.scss';

.DisplayOptions {

    h4 {
        text-transform: uppercase;
    }

    .DisplayMenu {
        width: 100vw;
        max-width: 400px;
    }

    .dropdown-toggle,
    .dropdown-item {
        //font-size: 13px;
        font-size: 14px;
    }

    .dropdown-toggle {
        &:focus {
            background: white;
            border: 1px solid black;
            color: black;
        }
    }
}