.Filter {

    margin: 10px 0;

    .filter-content {
        padding: 5px 0;
        max-height: 138px;
        overflow: auto;
        border: 1px solid rgba(0, 0, 0, 0.125);
        //accessibility
        font-size: 14px;
    }

    .searchable {
        max-height: 170px;
    }

    .control {
        display: flex;
        align-items: center;
        padding: 5px;

        h5 {
            margin: 0;
            flex: 1;
        }
    }

    .content-row {
        margin: 1px 0;
        position: relative;
    }

    .facet-count {
        position: absolute;
        right: 10px;
        top: 0;
        //color: #aaa;
        //accessibility
        color: #484848;
        font-size: 14px;

        .spinner-border {
            width: 1rem;
            height: 1rem;
            border-width: 1px;
        }
    }

    & .col {
        /*margin: 0;
    padding: 0;*/
    }

    .Header {
        cursor: pointer;

        &:focus {
            // border: 1px solid red;
            background: black;
            border: white;
            color: white;
        }
    }

    .Border {
        border-bottom: 1px solid #dfdfdf;
    }

    /*.true {
        display: block;
    }*/

    .false {
        display: none;
    }

    input {
        //font-size: 13px;
        font-size: 14px;
    }

    .DateSearch {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        button {
            //font-size: 13px;
            font-size: 14px;
        }
    }

    .DateInput {
        display: flex;
        margin-bottom: 3px;
        align-items: center;

        >div {
            width: 100%;
        }

        input {
            margin-left: 5px;
        }
    }
}

.caret-down {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.caret-up {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-bottom: 4px dashed;
    border-bottom: 4px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}